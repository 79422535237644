.date_table {
    width:95%;
}

.table {
    width: 100%;
}

.head {
    margin-bottom: 25px;
    justify-content: center;
}

.body {
    background-color: none;
}

.cell {
    border-style: none !important;
}

.day {
    width:35px;
}

.arrow {
    color: whitesmoke;
    cursor: pointer;
    transition: 0.5s all;
}

.arrow:hover {
    color: black;
}

.table_label {
    font-size: 22px;
    font-weight: bold;
}

@media screen and (max-device-width: 480px) {
    .arrow {
        display: none;
    }

    .table_label {
        font-size: 20px;
    }
    .arrow {
        color: black;
    }
}