@import '/src/styles/vars.scss';

.nav_link, .btn {
    color: black !important;
    transition:transform 0.25s;
    will-change: transform;
    background-color: white !important;
    &_active {
        background-color: $main-color !important;
    }
}

.btn {
    background-color: $main-color !important;
    border: none;
    width: 100%;
}

.nav_link:hover,.btn:hover {
    transform: translateY(-5px);
}

@media screen and (max-device-width: 480px) {
    header {
        display: none;
    }

    .nav_link {
        font-size: 16px;
    }
}

.logo {
    border-radius: 10px;
    margin-bottom: 20px;
}
