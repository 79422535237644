.wrapper {
    border: 1px solid lightgrey;
    box-shadow: -5px 5px whitesmoke;
    padding: 20px;
    border-radius: 10px;
}

.table_plug {
    border: 1px solid lightgrey;
    animation: jump 2s infinite;
    padding: 20px;
    border-radius: 10px;
    height: 50px;
}

.name {
    margin-left: 10px;
}

.cell_content {
    display: flex;
    justify-content: flex-end;
}

.desc {
    animation: appear 0.5s linear;
    padding: 10px;
    background-color: whitesmoke;
    text-align: start;
    &_btn {
        cursor: pointer;
        transition-duration: 0.5s;
        &_rotate_down {
            transform: rotate(180deg);
        }
        &_rotate_top {
            transform: rotate(0deg);
        }
    }
}

@keyframes appear {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@media screen and (max-width: 375px) {
    .wrapper {
        font-size: 14px;
    }
}

@keyframes jump {
    from {
        box-shadow: -5px 5px whitesmoke;
    }
    50% {
        box-shadow: -15px 15px whitesmoke;
    }
    to {
        box-shadow: -5px 5px whitesmoke;
    }
}