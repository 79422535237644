$color : #fcdb00;
$border-color: black;
$x1: 30px;
$x2: calc($x1 * 130 / 60);
$x3: calc($x1 * 2);
$x4: calc($x1 * 40 / 60);
$x5: calc($x1 * 125 / 60);

.item {
  cursor: pointer;
	display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
	border-radius: 100%;
	&_prev {
		opacity: 0.5;
	}
  &_text {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: -0.065em;
  }
  &_selected {
    color: white !important;
    background-color: black;
  }
}

@media screen and (min-device-width: 768px) {
  .item {
    width: 45px;
    height: 45px;
    font-size: 20px;
    padding: 18px;
    font-size: 20px;
  }
}

@media screen and (max-device-width: 480px) {
  .item {
      width: 30px;
      height: 30px;
      font-size: 16px;
    }
}