@import '/src/styles/vars.scss';

.mycarousel {
    background-color: $main-color;
    padding: 20px 0;
    border-radius: 10px;
    .surface {
        position: absolute;
        background: rgba($color: whitesmoke, $alpha: 0.5);
        padding: 20px;
        margin: 10px;
        border-radius: 10px;
        z-index: 10;
    }
}

.tablet {
    width:317px;
    height: 470px;
    border-radius: 20px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tablet__screen {
    width: 290px;
    height: 385px;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

@media screen and (max-device-width: 480px) {
    .inner {
        display: none;
    }
    .mycarousel {
        .surface {
            position: relative;
        }
    }
    
}