@import '/src/styles/vars.scss';

.calendar {
    padding:20px 0;
    box-shadow: -5px 5px whitesmoke;
    background-color: $main-color;
    border-radius: 5px;
}

@media screen and (max-device-width: 480px) {
    .calendar {
        font-size: 12px;
    }
}