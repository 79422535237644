@import '/src/styles/vars.scss';

.timelist-item {
    font-weight: bold;
    cursor: pointer;
    padding: 5px;
    border: 1px solid #f5f5f5;
    border-radius: 3px;
    box-shadow: -3px 3px #f5f5f5;
    transition: 0.5s all;
    user-select: none;
}

.timelist-item-selected {
    background-color: rgba($main-color, 0.7);
}

.timelist-item:hover, .timelist-item-active {
    background-color: $main-color;
}
