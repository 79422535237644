.content {
  background-color: white;
  border-radius: 10px;
  padding: 10px;
}

.sideBar_btn {
  display: none;
}

@media screen and (max-device-width: 480px) {
  .sideBar_btn {
    display: block;
    position: fixed;
    z-index: 5;
  }
}
