.spin {
    animation: spin 1s infinite linear;
    position: fixed;
    right:20px;
    bottom: 30px;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}